import React, { useEffect } from 'react'
import Header from './header/Header'
import '../../assets/Index.css'
import Footer from './footer/Footer'
import WaFloatButton from '../WaFloatButton';
export default function Layout({ children, title, activeLink }) {

    useEffect(() => {
        document.title = title;
    }, [title]);


    return (
        <>
            <Header activeLink={activeLink} title={title} />
            <main>
                <WaFloatButton />
                {children}
            </main>
            <Footer />
        </>
    )
}
