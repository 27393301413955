import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import './BrandingEssentials.css'; // Importa el archivo CSS

function BrandingEssentials() {
    const packages = [
        {
            name: 'MINI',
            features: [
                'Entrega en archivos originales',
                'Revisiones y Cambios: 20',
                'Tipografía de diseñador',
                'Colorimetría',
                'Papelería Corporativa: 20',
                'Manual de Marca',
                'Logotipo Animado',
                'Manpower: Diseñador Jr. | Project Manager',
                'Tiempo aprox. de entrega: 2 Semanas',
            ],
            buttonText: 'CONTRATAR MINI',
        },
        {
            name: 'LITE',
            features: [
                'Entrega en archivos originales',
                'Revisiones y Cambios: 30',
                'Tipografía de diseñador',
                'Colorimetría',
                'Papelería Corporativa: 30',
                'Manual de Marca',
                'Logotipo Animado',
                'Manpower: Diseñador Sr. | Diseñador Jr. | Project Manager',
                'Tiempo aprox. de entrega: 2 Semanas',
            ],
            buttonText: 'CONTRATAR LITE',
        },
        {
            name: 'PRO',
            features: [
                'Entrega en archivos originales',
                'Revisiones y Cambios: 40',
                'Tipografía de diseñador',
                'Colorimetría',
                'Papelería Corporativa: 40',
                'Manual de Marca',
                'Logotipo Animado',
                'Manpower: Dir. de Proyectos | Diseñador Jr. | Project Manager',
                'Tiempo aprox. de entrega: 3 Semanas',
            ],
            buttonText: 'CONTRATAR PRO',
        },
        {
            name: 'PLUS',
            features: [
                'Entrega en archivos originales',
                'Revisiones y Cambios: 50',
                'Tipografía de diseñador',
                'Colorimetría',
                'Papelería Corporativa: 50',
                'Manual de Marca',
                'Logotipo Animado',
                'Manpower: Dir. Creativo | Dir. de Proyectos | Diseñador Jr. | Project Manager',
                'Tiempo aprox. de entrega: 3 Semanas',
            ],
            buttonText: 'CONTRATAR PLUS',
        },
    ];

    return (
        <div className="container branding-essentials">
            <div className="row w-100">
                {packages.map((pkg, index) => (
                    <div key={index} className="col-12 col-sm-6 col-md-3 p-0">
                        <div className="container-plan-card">
                            <div className="container-namePlan">
                                <span className='plan-name'>PAQUETE</span>
                                <h4 className='plan-name'>{pkg.name}</h4>
                            </div>
                            <ul className="package-features">
                                {pkg.features.map((feature, featureIndex) => (
                                    <li key={featureIndex} className='feature-name'>{feature}</li>
                                ))}
                            </ul>
                            <div className="d-flex justify-content-center my-4">
                                <a href='https://api.whatsapp.com/send?phone=+527772590365&text=Hola Desarrollalab, quiero cotizar mi proyecto:'
                                    className="btn btn-solicitar-info fw-medium py-2">
                                    {pkg.buttonText} ➤➤
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

}

export default BrandingEssentials;