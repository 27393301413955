import React, { useState, useEffect } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";

export default function CarouselMockups() {
    const [goToSlide, setGoToSlide] = useState(0);
    const [offsetRadius, setOffsetRadius] = useState(2);
    const [showNavigation, setShowNavigation] = useState(true);
    const [animationConfig, setAnimationConfig] = useState(config.molasses);
    const [autoplayInterval, setAutoplayInterval] = useState(null); // Store interval ID

    const slides = [
        {
            number: 'PROYECTO 098',
            name: 'FE ABOGADOS',
            plan: 'PLAN / PREMIUM',
            content: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-feabogados.png?alt=media&token=257d5cb7-49dd-4929-b3e6-3c807871b9f7`,
            url: 'https://ea-abogados.com/',
            color: '#1C5780'
        },
        {
            number: 'PROYECTO 167',
            name: 'CDMXACCESORIOS',
            plan: 'PLAN / PREMIUM',
            content: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-cdmxaccesorios.png?alt=media&token=d974c80a-f14d-464d-a018-bdbfac8bb330`,
            url: 'https://www.cdmxaccesorios.com',
            color: '#1F5676'
        },
        {
            number: 'PROYECTO 180',
            name: 'CASA EN PAGOS',
            plan: 'PLAN / PREMIUM',
            content: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-casaenpagos.png?alt=media&token=975d8c3a-0059-45ae-b2a8-8b3057cc26ce`,
            url: 'https://casaenpagos.com',
            color: '#C959A0'
        },
        {
            number: 'PROYECTO 144',
            name: 'VIPARLARE',
            plan: 'PLAN / PREMIUM',
            content: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-viparlare.png?alt=media&token=a855299e-85ae-43c9-a15f-b3f2e121b84b`,
            url: 'https://viparlare.mx',
            color: '#335FA8'
        },
        {
            number: 'PROYECTO 125',
            name: 'CAUSA Y EFECTO',
            plan: 'PLAN / PREMIUM',
            content: `https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/mockups%2Fmockup-causayefecto.png?alt=media&token=8d266aac-6096-4ff6-b2fa-43b49aac8130`,
            url: 'https://causayefecto.mx',
            color: '#2BC3F2'
        }
    ];

    useEffect(() => {
        if (autoplayInterval) {
            clearInterval(autoplayInterval);
        }
        const intervalId = setInterval(() => {
            setGoToSlide(prev => (prev + 1) % slides.length);
        }, 4000); // Slide changes every 3 seconds

        setAutoplayInterval(intervalId);

        return () => {
            clearInterval(intervalId);
        };
    }, [goToSlide]);

    const slideItems = slides.map((slide, index) => ({
        key: index,
        content: (
            <div style={{ border: `${slide.color}`, padding: "20px", borderRadius: "10px", textAlign: "center" }}>
                <img src={slide.content} alt={slide.name} className="slide-carousel-image" />
                <div className="mt-4">
                    <h2 className="lower fw-medium">{slide.name}</h2>
                    <p className="text-sm fw-medium">{slide.plan}</p>
                </div>
            </div>
        )
    }));

    return (
        <div className="w-full h-screen">
            <Carousel
                slides={slideItems}
                goToSlide={goToSlide}
                offsetRadius={1}
                showNavigation={false}
                animationConfig={animationConfig}
                onChange={setGoToSlide}
            />
        </div>
    );
}
