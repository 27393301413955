import React from "react";

export default function WaFloatButton() {
  return (
    <div className="wa-container">
      <a
        href="https://api.whatsapp.com/send?phone=+527772590365&text=Hola, estoy interesado en el servicio de: "
        className="wa-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp"></i>
      </a>
      <span className="wa-label">Obtén atención inmediata</span>
    </div>
  );
}
