import React from 'react';
import { useInView } from 'react-intersection-observer';

export default function ServiceCard(props) {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    return (
        <div
            ref={ref}
            className={`position-relative container fade-in-up ${inView ? 'in-view' : ''}`}
        >
            <div className='container-card-service'>
                <div>
                    <img src={props.imgService} className="service-image" alt="" />
                </div>

                <div className="my-4">
                    <h3 className="fw-bold text-dark text-center">
                        {props.titleService}
                    </h3>
                </div>
                <div>
                    {props.subservices.map((subservice, index) => (
                        <p className="text-dark text-center" key={index}>
                            {subservice}
                        </p>
                    ))}
                </div>

                <div className="my-2">
                    <a className="btn btn-more-info" rel='noopener noreferrer' href={props.link}>Me interesa ➤➤</a>
                </div>
            </div>
        </div>
    );
}
