import React, { useEffect } from 'react'
import Hero from '../../components/Hero'
import HeroMobile from '../../components/HeroMobile'
import TitleSectionBadge from '../../components/TitleSectionBadge'
import IncludeServiceCard from '../../components/IncludeServiceCard'
import GridMockups from '../../components/GridMockups'
import PlanCard from '../../components/PlanCard'
import BrandingEssentials from './BrandingEssentials'


export default function DisenoDigital() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <div id="pc">
                <Hero
                    heroImage={true}
                    important="Diseño Digital y Branding"
                    description={"Desarrollo de páginas web profesionales, rápidas y modernas que cumplen los requisitos de Google"}
                    classHeroPage="heroPaginasWeb">
                </Hero>
            </div>

            <div id="mb">
                <HeroMobile
                    heroImage={true}
                    important="Páginas Web"
                    description={"Desarrollo de páginas web profesionales, rápidas y modernas que cumplen los requisitos de Google"}
                    classHeroPage="heroPaginasWeb">
                </HeroMobile>
            </div>

            <TitleSectionBadge description="Todos nuestros planes incluyen"></TitleSectionBadge>


            <div className="container-fluid">
                <BrandingEssentials />
            </div>

            <div className="container">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">

                    <div className="col my-3">
                        <IncludeServiceCard classIconService="diseno-web-icon"
                            title="Diseño profesional"
                            description="Utilizamos las mejores herramientas y las más adecuadas para cada cliente, desde lo más básico hasta lo más avanzado y complejo.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="cloud-icon"
                            title="Hospedaje Google Cloud"
                            description="Hospedamos tu sitio por un año en la plataforma más segura, rápida y extensa del planeta.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="speed-icon"
                            title="Código optimizado"
                            description="Usamos código optimizado para hacer que el sitio cargue lo más rápido posible.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="phone-icon"
                            title="Diseño responsivo"
                            description="El diseño del sitio web responde y se adapta a los dispositivos móviles en el que se está mostrando.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="ssl-icon"
                            title="Certificado de seguridad"
                            description="Ofrece seguridad a los visitantes de tu sitio con un certificado de seguridad SSL.">
                        </IncludeServiceCard>
                    </div>
                    <div className="col my-3">
                        <IncludeServiceCard classIconService="imagestock-icon"
                            title="Imágenes de stock"
                            description="Podemos proveer imágenes profesionales para ayudar a la imagen de su sitio.">
                        </IncludeServiceCard>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">

                    <div className="col my-3">
                        <PlanCard
                            planType="web"
                            planName="Básico"
                            workDays="Ideal para emprendimientos que buscan entrar al mundo digital y no saben por dónde empezar, este plan tiene todo lo necesario para tu emprendimiento"
                            planPrice="6,000"
                            discount="1500"
                        >
                        </PlanCard>
                    </div>
                    <div className="col my-3">
                        <PlanCard
                            planType="web"
                            planName="Prémium"
                            workDays="Perfecto para negocios ya establecidos y que buscan incluirse en el mundo digital o buscan formalizar su negocio en internet"
                            planPrice="8,000"
                            discount="1500"
                        >
                        </PlanCard>
                    </div>
                    <div className="col my-3">
                        <PlanCard
                            planType="web"
                            planName="Empresarial"
                            workDays="Si tienes una empresa corporativa y buscas un diseño de alto impácto y rendimiento tenemos la solución, llega a clientes potenciales por medio de una imagen corporativa"
                            planPrice="15,000"
                            discount="1500"
                        >
                        </PlanCard>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-center my-4">
                <span className='no-iva'>Los precios no incluyen IVA</span>
            </div>

            <TitleSectionBadge title="Conoce la calidad de nuestro trabajo"></TitleSectionBadge>
            <GridMockups showFullWork={false}></GridMockups>

            <div className="d-flex justify-content-center my-4">
                <span className='no-iva'>Los precios no incluyen IVA</span>
            </div>
        </div>
    )
}
