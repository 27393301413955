import React from 'react'
import '../assets/Index.css'
import ServiceCard from './ServiceCard'

export default function Services() {
    const services = [
        {
            titleService: "Diseño y Desarrollo Web",
            imgService: "https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/desarrollalab%2Fmockups-services%2Fservice-1.png?alt=media&token=fcb0f281-72c4-49d6-9792-195fb14e237c",
            subservices: [
                "Creación de páginas web",
                "Tiendas en línea | E-commerce",
                "Aplicaciones web",
                "Actualización y rediseño de sitios web",
            ],
            link: "/desarrollo-web"
        },
        {
            titleService: "Apps Móviles",
            imgService: "https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/desarrollalab%2Fmockups-services%2Fservice-4.png?alt=media&token=22f38469-4068-48b9-8191-17c3744841f6",
            subservices: [
                "Desarrollo de aplicaciones web",
                "Plataformas de mercado",
                "Flutter y React Native",
                "Desarrollo nativo en iOS y Android",
                "Actualización y mantenimiento de aplicaciones",
                "Diseño de experiencia de usuario (UX/UI)",
            ],
            link: "/aplicaciones-moviles"
        },
        {
            titleService: "Desarrollo de Software",
            imgService: "https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/desarrollalab%2Fmockups-services%2Fservice-3.png?alt=media&token=e5dff9fe-638e-4408-9f81-b3f01779327b",
            subservices: [
                "Desarrollo de software personalizado",
                "Desarrollo de software para empresas",
                "Soporte y mantenimiento de software",
                "Desarrollo Full Stack",
                "Consultoría",
            ],
            link: "/desarrollo-de-software"
        },
        {
            titleService: "Diseño Digital y Marketing",
            imgService: "https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/desarrollalab%2Fmockups-services%2Fservice-2.png?alt=media&token=0c95f0ff-094a-43ab-b0e6-c8832f46fae3",
            subservices: [
                "Creación de Logotipos e Identidad Corporativa",
                "Creación de contenido para redes sociales",
                "Publicidad online para aumentar ventas",
                "Consultoría",
            ],
            link: "https://wa.link/qnpwg7"
        }
    ]

    return (
        <div className="container mb-5">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                {services.map((service, index) => (
                    <div className="col" key={index}>
                        <ServiceCard
                            titleService={service.titleService}
                            imgService={service.imgService}
                            subservices={service.subservices}
                            link={service.link}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}
