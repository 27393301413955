import React from 'react'
import Slider from "react-slick"

export default function Clients() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        arrows: true,  // To add navigation arrows
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };

    const brandLogos = [
        "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2Fbrand_002_ignite.png?alt=media&token=ab27fd1b-5415-468b-a997-4bc81ea92b23",
        "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2Fbrand_004_mitsubichi.svg?alt=media&token=51eea798-522b-4ab4-b32c-6e60224778c5",
        "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2FOA_ARQUITECTOS_LOGOTIPO_BLANCO.jpg?alt=media&token=93098ce7-c1c8-4339-beed-0592f92b52fa",
        "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2Fbrand_003_clpa.png?alt=media&token=084331ae-2ccd-4d1d-88a5-3731cb6a2e31",
        "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2F001-logo-draedithlealcastaneda-resize.png?alt=media&token=f706b682-82a1-4df8-8bc4-6b0a24c5db21",
        "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2Flogo-reprovet1.png?alt=media&token=bd1dd99e-0e0a-45e5-8c84-3a879261ad6d",
        "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2Flogo_fos_resized.png?alt=media&token=4f32d295-1866-472b-8ca3-0d35c6b35dfc",
        "https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/brand_logos%2Flogo_idisraelita.png?alt=media&token=64d84238-3012-4208-bd0b-3910547d9a15"
    ];

    return (
        <div className='container hight-space'>
            <div className="col title-col my-4 text-center">
                <h1 className="text-dark title-brands">Con la <span className='text-purple-mexican'>confianza</span> de grandes empresas</h1>
            </div>

            <div className="hight-space"></div>

            <Slider {...settings}>
                {brandLogos.map((logo, index) => (
                    <div key={index} className="brand-item">
                        <img className="brand-logo" src={logo} alt={`brand-logo-${index}`} />
                    </div>
                ))}
            </Slider>
        </div>
    );
}
